import React from 'react'
import PropTypes from 'prop-types'
import { dateToYMD, scrollToTop, getAnswerByID } from '../utils'
import { useForm } from '../contexts/FormContext'

// Summary components
import ButtonHandler from './ButtonHandler'
import StickyNote from './summaryComponents/StickyNote'
/* import HorizontalBarChart from './summaryComponents/HorizontalBarChart' */
import NewHorizontalBarChart from './summaryComponents/NewHorizontalBarCharts'
import Scroll from './summaryComponents/Scroll'

// Material UI
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import Divider from '@material-ui/core/Divider'
import useStyles from '../styles'
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded'
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded'

// Images
import header from '../images/summary/header.png'
import entrefox_pdf_bg from '../images/background/pdf_background.png'

import html2pdf from 'html2pdf.js'
import { useTranslation } from 'react-i18next'

const Summary = ({ handleFormSubmit }) => {
	const { t: getTranslation } = useTranslation('general')
  const { t: getQuestions } = useTranslation('questions')
  const questions = getQuestions('questions', { returnObjects: true })

	const classes = useStyles()
	const { formData } = useForm()

	//const firstPart = content.find((part) => part.question === 2 && part.condition)
	// Get todays date
	const currentDate = dateToYMD(new Date())

	const downloadPDF = async () => {
		// scrolling up is necessary in order for the PDF to load correctly
		await scrollToTop(false)

		// Select and clone elements that are to be edited for the PDF
		const pdfPages = document.querySelectorAll('.pdf_page')

		pdfPages.forEach((pdfPage) => {
			pdfPage.style.paddingTop = '35px'
		})

		const element = document.getElementById('summary').cloneNode(true)
		const singlePage = document.querySelectorAll('.pdf_page')
		const lastPage = document
			.getElementById('last-pdf-page')
			.cloneNode(true)
		// Style settings for cloned elements
		// PDF page size: [215.9mm x 279.4mm]
		lastPage.style.height = '972px'
		element.style.backgroundImage = `url(${entrefox_pdf_bg})`
		element.style.backgroundSize = '100% 279.4mm'
		element.style.backgroundRepeat = 'repeat-y'
		element.style.padding = '15px 100px 0px 100px'
		singlePage.forEach((page) => (page.style.margin = '25px auto'))

		// Options for the html2pdf rendering
		const opt = {
			filename: 'entrefox_summary.pdf',
			image: { type: 'jpeg' },
			html2canvas: {
				scale: 1.25,
				pagebreak: { mode: 'avoid-all' },
				scrollX: -window.scrollX,
				scrollY: -window.scrollY,
				windowWidth: document.documentElement.offsetWidth,
				windowHeight: document.documentElement.offsetHeight,
			},
			jsPDF: { unit: 'mm', format: 'letter', orientation: 'portrait' },
			pagebreak: { mode: ['avoid-all', 'css', 'legacy', 'whiteline'] }
		}

		// Generate the PDF from the defined options
		window.open(await html2pdf().from(element).set(opt).output('bloburl'))

		// Change the styling back to the original after the PDF is finished rendering
		lastPage.style.height = 'auto'
		element.style.padding = ''
		element.style.backgroundImage = ''
		element.style.backgroundSize = ''
		singlePage.forEach((page) => (page.style.margin = 'auto'))

		pdfPages.forEach((pdfPage) => {
			pdfPage.style.paddingTop = '0px'
		})
	}

	const answers = {
		1: getAnswerByID(1, 1, formData).value,
		2: getAnswerByID(1, 2, formData).value,
		3: getAnswerByID(1, 3, formData).value,
		4: getAnswerByID(1, 4, formData).value,
		5: getAnswerByID(1, 5, formData).value,
		6: getAnswerByID(2, 6, formData).value,
		7: getAnswerByID(2, 7, formData).value,
		8: getAnswerByID(2, 8, formData).value,
		9: getAnswerByID(2, 9, formData).value,
		10: getAnswerByID(2, 10, formData).value,
		11: getAnswerByID(3, 11, formData).value,
		12: getAnswerByID(4, 12, formData).value ?? null,
		13: getAnswerByID(4, 13, formData).value ?? null,
		14: getAnswerByID(4, 14, formData).value ?? null,
		15: getAnswerByID(4, 15, formData).value ?? null,
		16: getAnswerByID(4, 16, formData).value ?? null,
		17: getAnswerByID(4, 17, formData).value ?? null,
		18: getAnswerByID(4, 18, formData).value ?? null,
		19: getAnswerByID(4, 19, formData).value ?? null,
		20: getAnswerByID(4, 20, formData).value ?? null,
		21: getAnswerByID(4, 21, formData).value ?? null
	}

	const calcFunct = (answer_value) => {
		let returned_value = ''
		if (answer_value.includes('-') === true) {
			if (answer_value.slice(0, 1) === '0') {
				returned_value = 15
			}
			returned_value = (parseInt(answer_value.slice(3, 6)) + parseInt(answer_value.slice(0, 2))) / 2
		}
		else {
			returned_value = parseInt(answer_value.slice(0, 3))
		}
		return returned_value
	}

	const weeklyCalcFunct = (answer_value) => {
		let returned_value = ''
    // Ei juurikaan
		if (answer_value === questions[0].questions[0].choices[0].text) {
			returned_value = 0
		}
		else if (answer_value.includes('-') === true) {
			returned_value = (parseInt(answer_value.slice(0, 1)) + parseInt(answer_value.slice(2, 3))) / 2
			return returned_value
		}
    // Kerran viikossa
		else if (answer_value === questions[0].questions[0].choices[1].text) {
			returned_value = 1
			return returned_value
		} else {
			returned_value = 6
			console.log(returned_value, answer_value)
			return returned_value
		}

		return returned_value
	}

	/* keskiarvo ? */


	return (
		<Container className={classes.survey} maxWidth='md'>
			<ButtonHandler
				text={getTranslation('go_back')}
				colors={{ bg: '#cddc39', bgHover: '#c0ca33' }}
				handlePagination={handleFormSubmit}
			/>

			{/* PDF starts here */}
			<Box id='summary'>
				<Box my={5}>
					<Typography
						component='h1'
						variant='h3'
						color='primary'
						align='center'
					>
						{getTranslation('health_summary_title.entrepreneur')}
					</Typography>
					<Typography variant='h4' component='h1' align='center' gutterBottom>
						{getTranslation('health_summary_title.health_summary')}
					</Typography>
					<Box mt={2} align='center'>
						<img src={header} className={classes.headingImage} />
					</Box>
					<Box mt={2}>
						<Typography variant='body1'>
							{getTranslation('summary_desc.id_1')}{' '}
							<Box fontWeight={500} component='span'>
								{currentDate}
							</Box>
							. {getTranslation('summary_desc.id_2')}{' '}
							<Link href='https://entrefox.fi/terveyskunnon-neljä-ulottuvuutta/'>
								{getTranslation('summary_desc.id_3')}
							</Link>
						</Typography>
					</Box>
				</Box>
				<Divider data-html2canvas-ignore='true' />

				{/* Manual page-break for the PDF generation */}
				<Box className='html2pdf__page-break'></Box>

				{/* Content starts here */}
				<Box mt={4}>
					<Box className='pdf_page' my={2}>
						<Box>
							<Typography color='primary' variant='h5'>
								{/* Lihaskunto ja liikkeenhallinta */}
								{getTranslation('muscle.id_1')}
							</Typography>
						</Box>
						<Box>
							<Typography>
								{getTranslation('muscle.id_2')} <strong>{answers[1]}</strong>.
								{getTranslation('muscle.id_3')}
							</Typography>
						</Box>
						<Box>
							<NewHorizontalBarChart
								title={
									getTranslation('muscle.id_1') +
									' ' +
									getTranslation('times_per.week')
								}
								recommended={2}
								current={weeklyCalcFunct(answers[1])}
								currentTitle={answers[1]}
								recommendedTitle={getTranslation('times_per.week_text')}
							/>
							{/* <HorizontalBarChart
							title='Lihaskunto ja liikkeenhallinta [krt/vko]'
							recommended={2}
							current={weeklyCalcFunct(answers[1])}
						/> */}
						</Box>
						<Box>
							<Typography>
								{getTranslation('muscle.id_4')}:
								<StickyNote answer={answers[6]} />
							</Typography>
						</Box>
						{answers[11].find(answer => answer.id === 1).isChecked ? (
							<Box className='pdf_page'>
								<Typography>
									{getTranslation('muscle.id_5')}:<strong>{answers[12]}</strong>
									{getTranslation('muscle.id_6')}:
								</Typography>
								<Scroll answer={answers[13]} />
							</Box>
						) : null}
						<Box className='html2pdf__page-break'></Box>
					</Box>
					<Box className='html2pdf__page-break'></Box>
					<Box className='pdf_page' my={2}>
						<Box>
							<Typography color='primary' variant='h5'>
								{/* Rasittava liikkuminen */}
								{getTranslation('strenuous_move.id_1')}
							</Typography>
						</Box>
						<Box>
							<Typography>
								{getTranslation('strenuous_move.id_2')}{' '}
								<strong>{answers[2]}</strong>.
								{getTranslation('strenuous_move.id_3')}
							</Typography>
						</Box>
						<Box>
							<NewHorizontalBarChart
								title={
									getTranslation('strenuous_move.id_1') +
									' ' +
									getTranslation('times_per.minute')
								}
								recommended={75}
								current={calcFunct(answers[2])}
								currentTitle={answers[2]}
								recommendedTitle={getTranslation('times_per.half_minutes_text')}
							/>
							{/* <HorizontalBarChart
								title='Rasittava liikkuminen [min/vko]'
								recommended={75}
								current={calcFunct(answers[2])}
							/> */}
						</Box>
						<Box>
							<Typography>
								{/* Rasittavaa liikkumista harrastat tällä hetkellä: */}
								{getTranslation('strenuous_move.id_4')}:
								<StickyNote answer={answers[7]} />
							</Typography>
						</Box>
						<Box className='html2pdf__page-break'></Box>
						{answers[11].find(answer => answer.id === 2).isChecked ? (
							<Box className='pdf_page'>
								<Typography>
									{getTranslation('theme.id_1')}: <strong>{answers[14]}</strong>{' '}
									{getTranslation('theme.id_2')}:
								</Typography>
								<Scroll answer={answers[15]} />
							</Box>
						) : null}
					</Box>
					<Box className='html2pdf__page-break'></Box>
					<Box className='pdf_page' my={8}>
						<Box>
							<Typography color='primary' variant='h5'>
								{/* Reipas liikkuminen */}
								{getTranslation('brisk_move.id_1')}
							</Typography>
						</Box>
						<Box>
							<Typography>
								{getTranslation('brisk_move.id_2')}{' '}
								<strong>{answers[3]}</strong>.
								{getTranslation('brisk_move.id_3')}
							</Typography>
						</Box>
						<Box>
							<NewHorizontalBarChart
								title={
									getTranslation('brisk_move.id_1') +
									' ' +
									getTranslation('times_per.minute')
								}
								recommended={150}
								current={calcFunct(answers[3])}
								currentTitle={answers[3]}
								recommendedTitle={getTranslation('times_per.minutes_text')}
							/>
						</Box>
						<Box>
							<Typography>
								{/* Reipasta liikkumista harrastat tällä hetkellä: */}
								{getTranslation('brisk_move.id_4')}:
								<StickyNote answer={answers[8]} />
							</Typography>
						</Box>
						{answers[11].find(answer => answer.id === 3).isChecked ? (
							<Box className='pdf_page'>
								<Typography>
									{getTranslation('theme.id_1')}: <strong>{answers[16]}</strong>{' '}
									{getTranslation('theme.id_2')}:
								</Typography>
								<Scroll answer={answers[17]} />
							</Box>
						) : null}
						<Box className='html2pdf__page-break'></Box>
					</Box>
					<Box className='pdf_page' my={8}>
						<Box>
							<Typography color='primary' variant='h5'>
								{/* Kevyt liikkuminen */}
								{getTranslation('light_move.id_1')}
							</Typography>
						</Box>
						<Box>
							<Typography>
								{getTranslation('light_move.id_2')}{' '}
								<strong>{answers[4]}</strong>.
								{getTranslation('light_move.id_3')}
							</Typography>
						</Box>
						<Box>
							<NewHorizontalBarChart
								title={
									getTranslation('light_move.id_1') +
									' ' +
									getTranslation('times_per.week')
								}
								recommended={2}
								current={weeklyCalcFunct(answers[4])}
								currentTitle={answers[4]}
								recommendedTitle={getTranslation('times_per.week_text')}
							/>
						</Box>
						<Box>
							<Typography>
								{/* Kevyttä liikuntaa harrastat tällä hetkellä: */}
								{getTranslation('light_move.id_4')}:
							</Typography>
							<StickyNote answer={answers[9]} />
						</Box>
						{answers[11].find(answer => answer.id === 4).isChecked ? (
							<Box className='pdf_page'>
								<Typography>
									{getTranslation('theme.id_1')}: <strong>{answers[18]}</strong>{' '}
									{getTranslation('theme.id_2')}:
								</Typography>
								<Box>
									<Scroll answer={answers[19]} />
								</Box>
								<Box className='html2pdf__page-break'></Box>
							</Box>
						) : null}
					</Box>
					<Box className='pdf_page' id='last-pdf-page' my={9}>
						<Box>
							<Typography color='primary' variant='h5'>
								{/* Tauot paikallaanoloon */}
								{getTranslation('pauses.id_1')}
							</Typography>
						</Box>
						<Box>
							<Typography>
								{getTranslation('pauses.id_2')} <strong>{answers[5]}</strong>.
								{getTranslation('pauses.id_3')}
							</Typography>
							<NewHorizontalBarChart
								title={
									getTranslation('pauses.id_1') +
									' ' +
									getTranslation('times_per.week')
								}
								recommended={7}
								current={weeklyCalcFunct(answers[5])}
								currentTitle={answers[5]}
								recommendedTitle={getTranslation('times_per.always_text')}
							/>
							<Typography>
								{/* Paikallaanolon tasapainoksi taukoja pidät tällä hetkellä: */}
								{getTranslation('pauses.id_4')}:
								<StickyNote answer={answers[10]} />
							</Typography>
						</Box>
						{answers[11].find(answer => answer.id === 5).isChecked ? (
							<Box className='pdf_page'>
								<Typography>
									{getTranslation('theme.id_1')}: <strong>{answers[20]}</strong>{' '}
									{getTranslation('theme.id_2')}:
								</Typography>
								<Scroll answer={answers[21]} />
							</Box>
						) : null}
					</Box>
				</Box>
			</Box>
			{/* PDF ends here */}

			<Box mt={2}>
				<Grid container direction='row' justify='space-between'>
					<Grid item>
						<Box my={1}>
							<ButtonHandler
								text={getTranslation('download_pdf')}
								colors={{ bg: '#cddc39', bgHover: '#c0ca33' }}
								startIcon={<GetAppRoundedIcon />}
								handlePagination={downloadPDF}
							/>
						</Box>
					</Grid>
					<Grid item>
						<Box my={1}>
							<ButtonHandler
								href='https://www.entrefox.fi/ajanhallinta/'
								text={getTranslation('end_form')}
								startIcon={<CheckCircleOutlineRoundedIcon />}
								colors={{
									bg: '#ffeb3b',
									bgHover: '#fbc02d',
								}}
							/>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</Container>
	)
}

Summary.propTypes = {
	handleFormSubmit: PropTypes.func
}

export default Summary
