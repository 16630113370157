import React, { useEffect } from 'react'
import { useForm } from './contexts/FormContext'
import { scrollToTop } from './utils'
import { getAnswerByID } from './utils'

// Components
import Survey from './components/Survey'
import Summary from './components/Summary'
import Footer from './components/Footer'

// Material UI
import Box from '@material-ui/core/Box'
import useStyles from './styles'
import Button from '@material-ui/core/Button'

import { useTranslation } from 'react-i18next'

import { ReactComponent as Finnish } from './images/flags/fi.svg'
import { ReactComponent as English } from './images/flags/uk.svg'

const App = () => {
	const {
		currentPage,
		setCurrentPage,
		formSubmitted,
		setFormSubmitted,
		formData,
	} = useForm()
	const classes = useStyles()

	const { t: getTranslation, i18n } = useTranslation('questions')
	const questionSets = getTranslation('questions', { returnObjects: true })

	const firstAnswer = getAnswerByID(1, 1, formData) // First question's value

	console.log(firstAnswer.value)

	// Scroll to top when page is changed
	useEffect(() => {
		scrollToTop(true)
	}, [currentPage])

	const handleNextPage = () => {
		setCurrentPage(currentPage => currentPage + 1)
	}

	const handlePreviousPage = () => {
		setCurrentPage(currentPage => currentPage - 1)
	}

	const handleFormSubmit = event => {
		event.preventDefault()
		setFormSubmitted(!formSubmitted)
		scrollToTop(true)
	}
	const changeLanguageHandler = lang => {
		i18n.changeLanguage(lang)
	}
	return (
		<Box className={classes.mainBackground}>
			<Box pt={5}>
				<Box align='center' margin m={2} pt={3}>
					{
						// Hide language switch because answer is stored as text not id for some reason
						!firstAnswer.value && (
							<div>
								<Button
									onClick={() => changeLanguageHandler('fi')}
									size='small'
									variant='outlined'
									style={{ margin: 2 }}
								>
									Finnish <Finnish style={{ paddingLeft: 5 }} />
								</Button>
								<Button
									onClick={() => changeLanguageHandler('en')}
									size='small'
									variant='outlined'
									style={{ margin: 2 }}
								>
									English <English style={{ paddingLeft: 5 }} />
								</Button>
							</div>
						)
					}
				</Box>
				{formSubmitted ? (
					<Summary handleFormSubmit={handleFormSubmit} />
				) : (
					<Survey
						handleFormSubmit={handleFormSubmit}
						handleNextPage={handleNextPage}
						handlePreviousPage={handlePreviousPage}
						questionSets={questionSets}
						currentPage={currentPage}
						formSubmitted={formSubmitted}
						formData={formData}
					/>
				)}

				<Footer />
			</Box>
		</Box>
	)
}

export default App
