import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import FormContextProvider from './contexts/FormContext'

import {
  ThemeProvider,
  createMuiTheme,
  responsiveFontSizes,
} from '@material-ui/core/styles'

import yellow from '@material-ui/core/colors/yellow'

// Translations
import './data/i18n'

let theme = createMuiTheme({
  palette: {
    primary: { main: '#AAD03F' },
    secondary: yellow,
  },
})

theme = responsiveFontSizes(theme)

ReactDOM.render(
  <FormContextProvider>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </FormContextProvider>,
  document.getElementById('root')
)
