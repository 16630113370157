import React from 'react'
import PropTypes from 'prop-types'

// Material UI
import Box from '@material-ui/core/Box'

// eslint-disable-next-line no-unused-vars
const NewHorizontalBarChart = ({
  //   title,
  recommended,
  current,
  currentTitle,
  recommendedTitle,
}) => {
  let currentRatio = (current / recommended) * 100
  let recommendedRatio = 100

  //   console.log('\n', currentTitle, current)
  //   console.log(recommendedTitle, recommended)
  // console.log(recommendedTitle, currentTitle)
  //   console.log('ratio before', currentRatio)

  // if current ratio is higher than 100% (current higher than recommended),
  // adjust recommended ratio to be lower instead
  if (currentRatio > 100) {
    currentRatio = 100
    recommendedRatio = (recommended / current) * 100
  } else if (currentRatio === 0) {
    currentRatio = 1
  }
  //   console.log('ratio', currentRatio)

  return (
    <Box style={{ height: '250px', width: '100%' }}>
      <table
        style={{
          alignItems: 'center',
        }}
      >
        <tr>
          <td
            style={{
              textAlign: 'center',
            }}
          >
            <p
              style={{
                fontSize: 12,
                width: '100px',

                fontFamily: 'Helvetica',
              }}
            >
              {/* Nykytila */}
              {currentTitle}
            </p>
          </td>
          <td style={{ width: '100%' }}>
            <p
              style={{
                backgroundColor: '#AAD03F',
                width: currentRatio.toString() + '%',
                paddingTop: '25px',
                paddingBottom: '25px',
                borderRadius: '5px',
              }}
            ></p>
          </td>
        </tr>
        <tr>
          <td
            style={{
              textAlign: 'center',
            }}
          >
            <span
              style={{
                fontSize: 12,
                marginTop: '-15px',
                fontFamily: 'Helvetica',
              }}
            >
              {/* Suositus */}
              {recommendedTitle}
            </span>
          </td>
          <td>
            <p
              style={{
                backgroundColor: '#F6EE24',
                width: recommendedRatio.toString() + '%',

                paddingTop: '25px',
                paddingBottom: '25px',
                borderRadius: '5px',
              }}
            ></p>
          </td>
        </tr>
      </table>
    </Box>
  )
}
NewHorizontalBarChart.propTypes = {
  title: PropTypes.string,
  recommended: PropTypes.number,
  current: PropTypes.string,
  currentTitle: PropTypes.string,
  recommendedTitle: PropTypes.string,
}
export default NewHorizontalBarChart
